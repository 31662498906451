
import { defineComponent, provide, ref, onMounted } from "vue";
import GameConversationForm from "@/components/Admins/GameConversations/GameConversationForm";
import { GameConversationsRepository } from "@/repositories/Admins/GameConversationsRepository";
import { GameConversationModel } from "@/models/GameConversationModel";

export default defineComponent({
  components: { GameConversationForm },
  setup() {
    const afterSubmit = () => {
      window.location.href = "/admins/game_conversations";
    };

    const gameConversationRepository = new GameConversationsRepository();
    const getRecord = async () => {
      const id = window.location.href.match(/\/([0-9].*)\/edit/)[1];
      const record = await gameConversationRepository.show(Number(id));
      return record;
    };

    // MEMO: setup関数ではawaitを待ってくれないのでまずrefをprovideしておいてmountされたときにDBのデータを取得してデータを渡している。
    let record = ref<any>(new GameConversationModel({}));
    provide("gameConversation", record);
    onMounted(async () => {
      const res = await getRecord();
      record.value = res;
    });

    return {
      afterSubmit
    };
  }
});

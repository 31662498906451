
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "PlainOrImage",
  props: {
    imageBaseUrl: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup(props) {
    const imagePath = computed(() => {
      return props.value;
    });

    const isImage = () => {
      if (props.value) {
        const match = props.value.match(/^.*\.(jpg|png)$/);
        return !!match;
      }
      return false;
    };

    return { imagePath, isImage };
  }
});

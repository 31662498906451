
import { defineComponent, inject, reactive, watch } from "vue";
import { DkidsQuestionPendingsRepository } from "@/repositories/Admins/DkidsQuestionPendingsRepository";
import { UserModel } from "@/models/UserModel";
import PlainOrImage from "@/components/Admins/DkidsQuestionPendings/PlainOrImage.vue";
import InfiniteLoading from "vue-infinite-loading";
import { QuestionPreviewSupporter } from "@/libs/QuestionPreviewSupporter";

interface State {
  loading: boolean;
  infiniteId: any,
  selectedQuestion: any,
  searchProps: any,
  grades: any[],
  questions: any[],
  questionTotal: number,
  currentUser: UserModel,
  publishButtonDisabled: any,
  remandButtonDisabled: any
}

interface Oruga {
  loading: any,
  modal: any,
  notification: any,
  config: any
}

export default defineComponent({
  components: {
    PlainOrImage, InfiniteLoading
  },
  props: {
    gamePreviewUrl: {
      type: String,
      default: ""
    },
    formats: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const state = reactive<State>({
      currentUser: new UserModel({}),
      loading: false,
      infiniteId: +new Date(),
      selectedQuestion: {},
      searchProps: {
        statusEq: "",
        gradeEq: "",
        questionCidEq: "",
        formatIdEq: "",
        page: 1,
        perPage: 50
      },
      grades: ["", 0, 1, 2, 3, 4, 5, 6],
      questions: [],
      questionTotal: 1,
      publishButtonDisabled: true,
      remandButtonDisabled: true
    });

    watch(() => state.selectedQuestion, (newQuestion) => {
      if (!newQuestion.questionCid) {
        state.publishButtonDisabled = true;
        state.remandButtonDisabled = true;
        return;
      }

      state.publishButtonDisabled = false;
      state.remandButtonDisabled = !newQuestion.comment;
    }, { deep: true });

    const showGrade = (grade) => {
      if (grade === 0) return "未就学児";

      return grade ? `${grade}年生` : "全学年";
    };

    const searchQuestions = async () => {
      try {
        state.searchProps.page = 1;
        state.questionTotal = 1;
        state.selectedQuestion = {};
        state.questions = [];
        state.infiniteId += 1;
        updateInfiniteLoadingStatus();
      } catch (e) {
        state.questions = [];
        state.selectedQuestion = {};
      }
    };

    // 無限スクロール領域の高さが変わったことをinfinite-loadingに通知する
    const updateInfiniteLoadingStatus = () => {
      state.infiniteId += 1;
    };

    const questionRequest = async () => {
      const repository = new DkidsQuestionPendingsRepository();
      const result = await repository.index({ searchParams: state.searchProps });
      state.questions = state.questions.concat(result.items);
      state.questionTotal = result.totalSize;
      state.currentUser = result.currentUser;
    };

    // ゲームのプレビューを表示する
    const onPreviewClick = (question) => {
      const questionPreviewSupporter = new QuestionPreviewSupporter(state);
      questionPreviewSupporter.onPreviewClick(question);
    };

    const infiniteHandler = async ($state) => {
      if (state.questions.length >= state.questionTotal) {
        // これ以上取得するデータがないとき
        $state.complete();
      } else {
        state.loading = true;
        await questionRequest();
        state.searchProps.page += 1;
        $state.loaded();
        state.loading = false;
      }
    };

    // composition apiでは$orugaにアクセスできないので、provideされたorugaを呼び出して使う
    // refs: https://github.com/oruga-ui/oruga/issues/168
    const oruga = inject<Oruga>("oruga");

    const submit = async (status) => {
      try {
        const repository = new DkidsQuestionPendingsRepository();
        await repository.update(state.selectedQuestion.id, submitParams((status)));
        dealWithCurrentRow(status);
        selectNextRow();

        oruga.notification.open({
          message: "更新に成功しました。",
          rootClass: "toast-notification",
          position: "top",
          variant: "success"
        });
      } catch (e) {
        oruga.notification.open({
          message: "更新に失敗しました。",
          rootClass: "toast-notification",
          position: "top",
          variant: "danger"
        });
      }
    };

    const submitParams = (status) => {
      return {
        dkidsQuestion: {
          status,
          comment: state.selectedQuestion.comment
        }
      };
    };

    const dealWithCurrentRow = (status) => {
      const currentRow = document.querySelector(`#tr-${state.selectedQuestion.questionCid}`);

      if (shouldHideRow(status)) {
        currentRow.classList.add("is-hidden");
      } else {
        // trの背景色をつける
        const colorClass = status === "remanded" ? "is-rebated" : "";
        if (colorClass) { currentRow.classList.add(colorClass); }
      }
    };

    const shouldHideRow = (status) => {
      // statusがpublished
      if (["published", "bk_published"].includes(status)) return true;
      if (status === "remanded") {
        // 0は承認待ち
        return state.searchProps.status === 0;
      }
      return false;
    };

    const selectNextRow = () => {
      const questionIndex = state.questions.indexOf(state.selectedQuestion);

      if (questionIndex !== -1 && state.questions[questionIndex + 1]) {
        onPreviewClick(state.questions[questionIndex + 1]);
      } else {
        state.selectedQuestion = {};
      }
    };

    // MEMO: ローカルストレージに検索用パラメータを保存しておく
    const saveSearchCondition = () => {
      if (state.searchProps.gradeEq !== null) {
        localStorage.setItem("search_grade", state.searchProps.gradeEq);
      } else {
        localStorage.removeItem("search_grade");
      }

      if (state.searchProps.formatIdEq) {
        localStorage.setItem("search_format_id", state.searchProps.formatIdEq);
      } else {
        localStorage.removeItem("search_format_id");
      }
    };

    // MEMO: ローカルストレージに保存されている検索条件をパラメータとしてセットする
    const applySearchCondition = () => {
      const grade = localStorage.getItem("search_grade");
      if (grade !== null) {
        state.searchProps.gradeEq = grade;
        localStorage.removeItem("search_grade");
      }

      const formatId = localStorage.getItem("search_format_id");
      if (formatId) {
        state.searchProps.formatIdEq = formatId;
        localStorage.removeItem("search_format_id");
      }
    };

    applySearchCondition();

    return {
      state,
      showGrade,
      searchQuestions,
      onPreviewClick,
      infiniteHandler,
      submit,
      saveSearchCondition
    };
  }
});


import { defineComponent, reactive, computed, ComputedRef } from "vue";
import { PlayAggregatesRepository } from "@/repositories/Admins/Analytics/PlayAggregatesRepository";

interface State {
  loading: boolean;
  searchProps: object,
  records: object[],
  selectedDateIndex: number
}

export default defineComponent({
  props: {
    dates: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const state = reactive<State>({
      loading: false,
      searchProps: {},
      records: [],
      selectedDateIndex: 0
    });

    // 一番直近の期間のものを初期では指定しておく
    state.selectedDateIndex = props.dates.length - 1;

    const selectedDate: any = computed(() => {
      return props.dates[state.selectedDateIndex];
    });

    const hasPrevMonth: ComputedRef<boolean> = computed(() => {
      return !!props.dates[state.selectedDateIndex - 1];
    });

    const hasNextMonth: ComputedRef<boolean> = computed(() => {
      return !!props.dates[state.selectedDateIndex + 1];
    });


    const playAggregatesRepostitory = new PlayAggregatesRepository();

    const getRecords = async () => {
      try {
        const params = {
          from: selectedDate.value.date
        };
        const { items  } = await playAggregatesRepostitory.index(params);
        state.records = items;
      } catch (e) {
        state.records = [];
      }
    };

    getRecords();

    const searchNextMonth = async () => {
      state.selectedDateIndex += 1;
      await getRecords();
    };

    const searchPrevMonth = async () => {
      state.selectedDateIndex -= 1;
      await getRecords();
    };

    return {
      state,
      selectedDate,
      hasPrevMonth,
      hasNextMonth,
      searchPrevMonth,
      searchNextMonth
    };
  }
});

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isImage())
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: _ctx.imagePath,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: _ctx.imagePath,
            alt: "",
            crossorigin: ""
          }, null, 8, _hoisted_2)
        ], 8, _hoisted_1))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString(_ctx.$props.value), 1)
        ], 64))
  ]))
}

import { defineComponent  } from "vue";
export default defineComponent({
  name: "ErrorMessage",
  props: {
    errors: {
      type: Array,
      required: false,
      default: () => []
    }
  }
});


import { defineComponent, reactive, computed } from "vue";
import { DkidsQuestionsRepository } from "@/repositories/Admins/DkidsQuestionsRepository";
import InfiniteLoading from "vue-infinite-loading";
import PlainOrImage from "@/components/Admins/DkidsQuestionPendings/PlainOrImage.vue";
import { QuestionPreviewSupporter } from "@/libs/QuestionPreviewSupporter";

interface State {
  loading: boolean;
  infiniteId: any,
  selectedQuestion: any,
  searchProps: any,
  questions: any[],
  questionTotal: number,
}

export default defineComponent({
  components: {
    InfiniteLoading, PlainOrImage
  },
  props: {
    gamePreviewUrl: {
      type: String,
      default: ""
    },
    isAdminUser: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const urlParams = new URLSearchParams(window.location.search);
    const grade = urlParams.get("grade");

    const state = reactive<State>({
      loading: false,
      infiniteId: +new Date(),
      selectedQuestion: {},
      searchProps: {
        page: 1,
        perPage: 50,
        grade
      },
      questions: [],
      questionTotal: 1
    });

    // 無限スクロール領域の高さが変わったことをinfinite-loadingに通知する
    const updateInfiniteLoadingStatus = () => {
      state.infiniteId += 1;
    };

    const questionRequest = async () => {
      const repository = new DkidsQuestionsRepository();
      const result = await repository.index(state.searchProps);
      state.questions = state.questions.concat(result.items);
      state.questionTotal = result.totalCount;
      updateInfiniteLoadingStatus();
    };

    // ゲームのプレビューを表示する
    const onPreviewClick = (question) => {
      const questionPreviewSupporter = new QuestionPreviewSupporter(state);
      questionPreviewSupporter.onPreviewClick(question);
    };

    const infiniteHandler = async ($state) => {
      if (state.questions.length >= state.questionTotal) {
        // これ以上取得するデータがないとき
        $state.complete();
      } else {
        state.loading = true;
        await questionRequest();
        state.searchProps.page += 1;
        $state.loaded();
        state.loading = false;
      }
    };

    const editPathLabel = computed(() => {
      return props.isAdminUser ? "編集" : "詳細";
    });

    return {
      state,
      onPreviewClick,
      infiniteHandler,
      editPathLabel,
      grade
    };
  }
});

export class QuestionPreviewSupporter {
  public state: any;

  constructor(state) {
    this.state = state;
  }

  // hiddenタグに問題表示に必要な値をセットして、changeイベントを発火させる
  onPreviewClick(question) {
    const hiddenTag = document.getElementById("js_review_game_json_params");
    this.setGameParams(hiddenTag, question.gameParams());
    this.setSchoolQuestion(question);

    const changeEvent = new Event("change", { bubbles: true, composed: true });
    hiddenTag.dispatchEvent(changeEvent); // Changeイベントを発火させる

    this.toggleBackgroundColor(question.questionCid);
  };
  // hiddenタグのvalueにセット
  setGameParams(hiddenTag, value) {
    hiddenTag.value = JSON.stringify(value);
  };

  setSchoolQuestion (question) {
    this.state.selectedQuestion = question;
  };

  toggleBackgroundColor(questionCid) {
    const backgroundColorClass = "preview-selected";
    const unselectedTr = document.querySelector(`tr.${backgroundColorClass}`);
    if (unselectedTr) unselectedTr.classList.remove(backgroundColorClass);

    const selectedTr = document.getElementById(`tr-${questionCid}`);
    selectedTr.classList.add(backgroundColorClass);
  };
}

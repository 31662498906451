import { BaseRepository } from "@/repositories/BaseRepository";
import { DkidsQuestionFormatModel } from "@/models/DkidsQuestionFormatModel";

export class DkidsQuestionFormatsRepository extends BaseRepository {
  async index(params = {}) {
    const url = `/admins/dkids_question_formats.json`;
    const results = await this._get(url, params);
    const items = results.data.records.map((item: any) => {
      return new DkidsQuestionFormatModel(item);
    });
    return { items }
  }
}

import { createApp } from "vue";
import router from "~/common/router";
import Index from "~/components/Admins/DkidsQuestionPendings/Index";
import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from "@oruga-ui/theme-bulma";

router("admins/dkids_question_pendings", ["index"], (vueProps) => {
  const props = vueProps;
  document.addEventListener("DOMContentLoaded", () => {
    const index = createApp(Index, props);
    index.use(Oruga, bulmaConfig);
    index.mount("#index");
  });
});


import { defineComponent, provide, ref } from "vue";
import GameConversationForm from "@/components/Admins/GameConversations/GameConversationForm";
import { GameConversationModel } from "@/models/GameConversationModel";
export default defineComponent({
  components: { GameConversationForm },
  setup() {
    const afterSubmit = () => {
      window.location.href = "/admins/game_conversations";
    };

    const gameConversation = ref<any>(new GameConversationModel({}));
    provide("gameConversation", gameConversation);

    return {
      afterSubmit
    };
  }
});

import { BaseRepository } from "@/repositories/BaseRepository";
import { DkidsQuestionModel } from "@/models/DkidsQuestionModel";

export class DkidsQuestionsRepository extends BaseRepository {
  async index(params = {}) {
    const url = `/admins/dkids_questions.json`;
    const DkidsQuestionModelParams = new DkidsQuestionModel(params);
    const results = await this._get(url, DkidsQuestionModelParams.toApiParams());
    const items = results.data.dkids_questions.map((item: any) => {
      return new DkidsQuestionModel(item);
    });
    const totalCount = results.data.total_count;
    return { items, totalCount }
  }
}

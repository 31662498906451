
import { defineComponent, reactive } from "vue";
import { NewsArticlesRepository } from "@/repositories/Admins/NewsArticlesRepository";
import InfiniteLoading from "vue-infinite-loading";

interface State {
  loading: boolean;
  infiniteId: any,
  searchProps: any,
  records: any[],
  totalCount: number
}

export default defineComponent({
  components: { InfiniteLoading },
  setup() {
    const state = reactive<State>({
      loading: false,
      infiniteId: +new Date(),
      searchProps: {
        page: 1,
        per_page: 50,
        q: {
          sort_by_released_on_desc: true
        }
      },
      records: [],
      totalCount: 0
    });
    const newsArticlesRepository = new NewsArticlesRepository();

    const getRecords = async () => {
      try {
        const { items, totalCount } = await newsArticlesRepository.index(state.searchProps);
        state.records = state.records.concat(items);
        state.totalCount = totalCount;
      } catch (e) {
        state.records = [];
        state.totalCount = 0;
      } finally {
        updateInfiniteLoadingStatus();
      }
    };

    // 無限スクロール領域の高さが変わったことをinfinite-loadingに通知する
    const updateInfiniteLoadingStatus = () => {
      state.infiniteId += 1;
    };

    const infiniteHandler = async ($state) => {
      if (state.records.length >= state.totalCount) {
        // これ以上取得するデータがないとき
        $state.complete();
      } else {
        state.loading = true;
        state.searchProps.page += 1;
        await getRecords();
        $state.loaded();
        state.loading = false;
      }
    };

    getRecords();

    return {
      state,
      infiniteHandler
    };
  }
});

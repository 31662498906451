import { BaseRepository } from "@/repositories/BaseRepository";
import { DailyQuestionFormatModel } from "@/models/DailyQuestionFormatModel";

export class DailyQuestionFormatsRepository extends BaseRepository {
  async index(params = {}) {
    const url = `/admins/daily_question_formats.json`;
    const DkidsQuestionModelParams = new DailyQuestionFormatModel(params);
    const results = await this._get(url, DkidsQuestionModelParams.toApiParams());
    const items = results.data.records.map((item: any) => {
      return new DailyQuestionFormatModel(item);
    });
    const questionFormats = results.data.question_formats;

    return { items, questionFormats }
  }
}

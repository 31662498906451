import { BaseModel } from "@/models/BaseModel";

export class NewsArticleModel extends BaseModel {
  public id: number;
  public status: string;
  public content: string;
  public released_on: Date;

  static statuses() {
    return [
      { value: "draft", name: "下書き" },
      { value: "released", name: "公開" }
    ]
  }

  showStatus() {
    const statusObject = NewsArticleModel.statuses().find(statusObj => statusObj.value === this.status)
    return statusObject?.name
  }
};

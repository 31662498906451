
import { defineComponent, reactive } from "vue";
import NewsArticleForm from "@/components/Admins/NewsArticles/NewsArticleForm";
import { NewsArticleModel } from "@/models/NewsArticleModel";
export default defineComponent({
  components: { NewsArticleForm },
  setup() {
    const state = reactive<
    {
      newsArticle: NewsArticleModel
    }
    >({
      newsArticle: new NewsArticleModel({ status: "draft" })
    });
    const afterSubmit = () => {
      window.location.href = "/admins/news_articles";
    };
    return {
      state,
      afterSubmit
    };
  }
});

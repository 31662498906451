
import { computed, defineComponent, inject, reactive } from "vue";
import ErrorMessage from "@/components/forms/ErrorMessage";
import { UnkoCollectionModel } from "@/models/UnkoCollectionModel";
import { UnkoCollectionsRepository } from "@/repositories/Admins/UnkoCollectionsRepository";
interface Oruga {
  loading: any,
  modal: any,
  notification: any,
  config: any
}
export default defineComponent({
  name: "UnkoCollectionForm",
  components: { ErrorMessage },
  props: {
    unkoCollection: {
      required: true,
      type: UnkoCollectionModel
    }
  },
  setup(props, context) {
    // composition apiでは$orugaにアクセスできないので、provideされたorugaを呼び出して使う
    // refs: https://github.com/oruga-ui/oruga/issues/168
    const oruga = inject<Oruga>("oruga");
    const state = reactive<{
      loading: boolean,
      errors: any[]
    }>({
      loading: false,
      errors: []
    });
    const unkoCollectionRepository = new UnkoCollectionsRepository();

    const submit = async () => {
      try {
        state.loading = true;
        const params = createParams();
        if (isNew.value) {
          await unkoCollectionRepository.create(params);
        } else {
          await unkoCollectionRepository.update(props.unkoCollection.id, params);
        }
        state.errors = [];
        oruga.notification.open({
          message: "保存に成功しました。",
          rootClass: "toast-notification",
          position: "top",
          variant: "success"
        });
        context.emit("afterSubmit");
      } catch ({ response }) {
        state.errors = response.data.errors;
        oruga.notification.open({
          message: "保存に失敗しました。",
          rootClass: "toast-notification",
          position: "top",
          variant: "danger"
        });
      } finally {
        state.loading = false;
      }
    };
    const createParams = () => {
      const params = {
        unko_collection: props.unkoCollection.toApiParams()
      };
      return params;
    };

    const isNew = computed(() => {
      return !props.unkoCollection.id;
    });

    return {
      state,
      isNew,
      submit
    };
  }
});

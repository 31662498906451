
import { defineComponent, reactive } from "vue";
import UnkoCollectionForm from "@/components/Admins/UnkoCollections/UnkoCollectionForm";
import { UnkoCollectionModel } from "@/models/UnkoCollectionModel";
import { UnkoCollectionsRepository } from "@/repositories/Admins/UnkoCollectionsRepository";

export default defineComponent({
  components: { UnkoCollectionForm },
  setup() {
    const state = reactive<
    {
      unkoCollection: UnkoCollectionModel
    }
    >({
      unkoCollection: new UnkoCollectionModel({})
    });
    const unkoCollectionRepository = new UnkoCollectionsRepository();
    const afterSubmit = () => {
      window.location.href = "/admins/unko_collections";
    };

    const getRecord = async () => {
      const id = window.location.href.match(/\/([0-9].*)\/edit/)[1];
      const record = await unkoCollectionRepository.show(Number(id));
      state.unkoCollection = record;
    };

    getRecord();

    return {
      state,
      afterSubmit
    };
  }
});

import { BaseRepository } from "@/repositories/BaseRepository";
import { NewsArticleModel } from "@/models/NewsArticleModel";

export class NewsArticlesRepository extends BaseRepository {
  async index(params = {}) {
    const url = `/admins/news_articles.json`;
    const results = await this._get(url, params);
    const items = results.data.records.map((item: any) => {
      return new NewsArticleModel(item);
    });
    const totalCount = results.data.total_count;
    return { items, totalCount }
  }

  async show(id: number, params = {}) {
    const url = `/admins/news_articles/${id}.json`;
    const result = await this._get(url, params);
    return new NewsArticleModel(result.data);
  }

  async create(params = {}) {
    const url = "/admins/news_articles.json";
    const result = await this._post(url, params);
    return new NewsArticleModel(result);
  }

  async update(id: number, params = {}) {
    const url = `/admins/news_articles/${id}.json`;
    const result = await this._put(url, params);
    return new NewsArticleModel(result);
  }
}
import { BaseRepository } from "@/repositories/BaseRepository";
import { DkidsQuestionModel } from "@/models/DkidsQuestionModel";
import { UserModel } from "@/models/UserModel";

export class DkidsQuestionPendingsRepository extends BaseRepository {
  async index(params = {}) {
    const url = `/admins/dkids_question_pendings.json`;
    const DkidsQuestionModelParams = new DkidsQuestionModel(params);
    const results = await this._get(url, DkidsQuestionModelParams.toApiParams());
    const items = results.data.dkids_questions.map((item: any) => {
      return new DkidsQuestionModel(item);
    });
    const totalSize = results.data.dkids_question_size;
    const currentUser = new UserModel(results.data.current_user);
    return { items, totalSize, currentUser }
  }

  async update(id: number, params = {}) {
    const url = `/admins/dkids_question_pendings/${id}.json`;
    const DkidsQuestionModelParams = new DkidsQuestionModel(params);
    const result = await this._put(url, DkidsQuestionModelParams.toApiParams());
    return new DkidsQuestionModel(result);
  }
}

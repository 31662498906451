
import { computed, defineComponent, inject, reactive } from "vue";
import ErrorMessage from "@/components/forms/ErrorMessage";
import { NewsArticleModel } from "@/models/NewsArticleModel";
import { NewsArticlesRepository } from "@/repositories/Admins/NewsArticlesRepository";
interface Oruga {
  loading: any,
  modal: any,
  notification: any,
  config: any
}
export default defineComponent({
  components: { ErrorMessage },
  props: {
    newsArticle: {
      required: true,
      type: NewsArticleModel
    }
  },
  setup(props, context) {
    // composition apiでは$orugaにアクセスできないので、provideされたorugaを呼び出して使う
    // refs: https://github.com/oruga-ui/oruga/issues/168
    const oruga = inject<Oruga>("oruga");
    const state = reactive<{
      loading: boolean,
      errors: any[],
      statuses: any[]
    }>({
      loading: false,
      errors: [],
      statuses: NewsArticleModel.statuses()
    });
    const newsArticleRepository = new NewsArticlesRepository();

    const submit = async () => {
      try {
        state.loading = true;
        const params = createParams();
        if (isNew.value) {
          await newsArticleRepository.create(params);
        } else {
          await newsArticleRepository.update(props.newsArticle.id, params);
        }
        state.errors = [];
        oruga.notification.open({
          message: "保存に成功しました。",
          rootClass: "toast-notification",
          position: "top",
          variant: "success"
        });
        context.emit("afterSubmit");
      } catch ({ response }) {
        state.errors = response.data.errors;
        oruga.notification.open({
          message: "保存に失敗しました。",
          rootClass: "toast-notification",
          position: "top",
          variant: "danger"
        });
      } finally {
        state.loading = false;
      }
    };
    const createParams = () => {
      const params = {
        news_article: props.newsArticle.toApiParams()
      };
      return params;
    };

    const isNew = computed(() => {
      return !props.newsArticle.id;
    });

    return {
      state,
      isNew,
      submit
    };
  }
});

import { BaseRepository } from "@/repositories/BaseRepository";
import { UnkoCollectionModel } from "@/models/UnkoCollectionModel";

export class UnkoCollectionsRepository extends BaseRepository {
  async index(params = {}) {
    const url = `/admins/unko_collections.json`;
    const results = await this._get(url, params);
    const items = results.data.records.map((item: any) => {
      return new UnkoCollectionModel(item);
    });
    const totalCount = results.data.total_count;
    return { items, totalCount }
  }

  async show(id: number, params = {}) {
    const url = `/admins/unko_collections/${id}.json`;
    const result = await this._get(url, params);
    return new UnkoCollectionModel(result.data);
  }

  async create(params = {}) {
    const url = "/admins/unko_collections.json";
    const result = await this._post(url, params);
    return new UnkoCollectionModel(result);
  }

  async update(id: number, params = {}) {
    const url = `/admins/unko_collections/${id}.json`;
    const result = await this._put(url, params);
    return new UnkoCollectionModel(result);
  }
}

import { BaseModel } from "@/models/BaseModel";

export class DailyQuestionFormatModel extends BaseModel {
  public id: number;
  public playDate: string;
  public zeroGradeFormatId: number
  public firstGradeFormatId: number
  public secondGradeFormatId: number
  public thirdGradeFormatId: number
  public fourthGradeFormatId: number
  public fifthGradeFormatId: number
  public sixthGradeFormatId: number
}

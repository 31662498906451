import camelCase from "camelcase";
import snakeCaseKeys from "snakecase-keys";

export class BaseModel {
  constructor(args: any) {
    Object.assign(this, camelizeKeys(args));
  }

  toApiParams() {
    return snakeCaseKeys(this);
  }
}

const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

import { BaseRepository } from "@/repositories/BaseRepository";
import { BaseModel } from "@/models/BaseModel";

export class PlayAggregatesRepository extends BaseRepository {
  async index(params = {}) {
    const url = `/admins/analytics/play_aggregates.json`;
    const results = await this._get(url, params);
    const items = results.data.map((item: any) => {
      return new BaseModel(item);
    });
    return { items }
  }
}

import { BaseModel } from "@/models/BaseModel";

export class GameConversationModel extends BaseModel {
  public id: number;
  public content: string;
  public conditionValue: string;
  public conversationConditionId: number;
  public dkidsQuestionFormatId: number;
  public timing: string;

  static timings() {
    return [
      { value: "start", name: "スタート時", formatIdDisabled: true, conversationConditionIdDisabled: true },
      { value: "game_start", name: "ゲーム開始時", formatIdDisabled: false, conversationConditionIdDisabled: false },
      { value: "game_end", name: "ゲーム終了時", formatIdDisabled: false, conversationConditionIdDisabled: false },
      { value: "next_play_guidance", name: "翌日のプレイ案内", formatIdDisabled: true, conversationConditionIdDisabled: true }
    ]
  }

  static formatIdDisabledTimings() {
    return GameConversationModel.timings().filter(timing => timing.formatIdDisabled);
  }

  static conversationConditionIdDisabledTimings() {
    return GameConversationModel.timings().filter(timing => timing.conversationConditionIdDisabled);
  }

  showTiming() {
    const timing = GameConversationModel.timings().find(tmp => tmp.value === this.timing);
    return timing?.name;
  }

  showContent() {
    return this.content.length > 50 ? `${this.content.slice(0, 50)}…` : this.content;
  }
}

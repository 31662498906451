import { createApp } from "vue";
import router from "~/common/router";
import Show from "~/components/Admins/Analytics/Dkids/Show";
import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from "@oruga-ui/theme-bulma";

router("admins/analytics/dkids", ["show"], (vueProps) => {
  const props = vueProps;
  document.addEventListener("DOMContentLoaded", () => {
    const app = createApp(Show, props);
    app.use(Oruga, bulmaConfig);
    app.mount("#show");
  });
});

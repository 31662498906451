import { BaseRepository } from "@/repositories/BaseRepository";
import { GameConversationModel } from "@/models/GameConversationModel";

export class GameConversationsRepository extends BaseRepository {
  async index(params = {}) {
    const url = `/admins/game_conversations.json`;
    const results = await this._get(url, params);
    const items = results.data.records.map((item: any) => {
      return new GameConversationModel(item);
    });
    const totalCount = results.data.total_count;
    return { items, totalCount }
  }

  async show(id: number, params = {}) {
    const url = `/admins/game_conversations/${id}.json`;
    const result = await this._get(url, params);
    return new GameConversationModel(result.data);
  }

  async create(params = {}) {
    const url = "/admins/game_conversations.json";
    const result = await this._post(url, params);
    return new GameConversationModel(result);
  }

  async update(id: number, params = {}) {
    const url = `/admins/game_conversations/${id}.json`;
    const result = await this._put(url, params);
    return new GameConversationModel(result);
  }
}

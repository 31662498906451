
import { defineComponent, PropType } from "vue";
import { DailyQuestionFormatModel } from "@/models/DailyQuestionFormatModel";

interface QuestionFormats {
  key: string,
  value: number
}

export default defineComponent({
  name: "DailyQuestionFormatTable",
  props: {
    dailyQuestionFormats: {
      type: Array as PropType<DailyQuestionFormatModel[]>,
      required: true
    },
    questionFormats: {
      type: Array as PropType<QuestionFormats[]>,
      required: true
    }
  },
  setup(props) {
    const showFormat = (id: number) => {
      const format = props.questionFormats.find(item => item.value === id);
      return format?.key;
    };

    return { showFormat };
  }
});

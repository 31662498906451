import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "errors-wrap"
}
const _hoisted_2 = { class: "errors-items" }
const _hoisted_3 = { class: "has-text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.errors && _ctx.errors.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
            return (_openBlock(), _createElementBlock("li", null, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(error), 1)
            ]))
          }), 256))
        ])
      ]))
    : _createCommentVNode("", true)
}

import { computed, defineComponent, inject, reactive, watch } from "vue";
import ErrorMessage from "@/components/forms/ErrorMessage";
import { GameConversationModel } from "@/models/GameConversationModel";
import { GameConversationsRepository } from "@/repositories/Admins/GameConversationsRepository";
import { ConversationConditionsRepository } from "@/repositories/Admins/ConversationConditionsRepository";
import { DkidsQuestionFormatsRepository } from "@/repositories/Admins/DkidsQuestionFormatsRepository";

interface Oruga {
  loading: any,
  modal: any,
  notification: any,
  config: any
}
export default defineComponent({
  components: { ErrorMessage },
  setup(_, context) {
    // composition apiでは$orugaにアクセスできないので、provideされたorugaを呼び出して使う
    // refs: https://github.com/oruga-ui/oruga/issues/168
    const oruga = inject<Oruga>("oruga");
    const gameConversation = reactive(inject<any>("gameConversation"));

    const state = reactive<{
      loading: boolean,
      errors: any[],
      timings: any[]
      conditions: any[],
      formats: any[]
      formatIdDisabledTimings: any[]
      conversationConditionIdDisabledTimings: any[]
    }>({
      loading: false,
      errors: [],
      timings: GameConversationModel.timings(),
      conditions: [],
      formats: [],
      formatIdDisabledTimings: GameConversationModel.formatIdDisabledTimings(),
      conversationConditionIdDisabledTimings: GameConversationModel.conversationConditionIdDisabledTimings()
    });
    const gameConversationRepository = new GameConversationsRepository();

    const submit = async () => {
      try {
        state.loading = true;
        const params = createParams();
        if (isNew.value) {
          await gameConversationRepository.create(params);
        } else {
          await gameConversationRepository.update(gameConversation.value.id, params);
        }
        state.errors = [];
        oruga.notification.open({
          message: "保存に成功しました。",
          rootClass: "toast-notification",
          position: "top",
          variant: "success"
        });
        context.emit("afterSubmit");
      } catch ({ response }) {
        state.errors = response.data.errors;
        oruga.notification.open({
          message: "保存に失敗しました。",
          rootClass: "toast-notification",
          position: "top",
          variant: "danger"
        });
      } finally {
        state.loading = false;
      }
    };
    const createParams = () => {
      const params = {
        game_conversation: gameConversation.value.toApiParams()
      };
      return params;
    };

    const isNew = computed(() => {
      return !gameConversation.value.id;
    });

    // 「条件の値」の入力が必要な「条件」を取得
    const conditionValueNeededCondition = computed(() => {
      return state.conditions.find(condition => condition.isConditionValueNeeded());
    });

    // 「条件」によって、「条件の値」を未入力にする必要がある
    const conditionValueDisabled = computed(() => {
      if (!conditionValueNeededCondition.value) return false;
      if (gameConversation.value.conversationConditionId !== conditionValueNeededCondition.value.id) return true;

      return false;
    });

    // 「問題形式」が入力不可能な状態かどうか
    const formatIdDisabled = computed(() => {
      if (!gameConversation.value.timing) return false;

      if (state.formatIdDisabledTimings.some(timing => timing.value === gameConversation.value.timing)) return true;

      return false;
    });

    // 「条件」が入力不可能な状態かどうか
    const conversationConditionIdDisabled = computed(() => {
      if (!gameConversation.value.timing) return false;

      if (state.conversationConditionIdDisabledTimings.some(timing => timing.value === gameConversation.value.timing)) return true;

      return false;
    });

    // タイミングによって選択できる条件を変更するためフィルタリングを行う
    // フィルタリング条件はConversationCondition.rbの timings で定義されている
    const filteredConditions = computed(() => {
      if (!gameConversation.value.timing) return state.conditions;

      return state.conditions.filter(condition => {
        return condition.timings.includes(gameConversation.value.timing);
      });
    });

    // 選択された「条件」によって「条件の値」を未入力状態にする
    watch(() => gameConversation.value, (newGameConversation) => {
      if (!conditionValueNeededCondition.value) return;
      if (!newGameConversation) return;

      if (newGameConversation.conversationConditionId !== conditionValueNeededCondition.value.id) {
        // MEMO: 条件の値の未入力を強制する
        newGameConversation.conditionValue = null;
      }
    }, { deep: true });

    // 選択された「タイミング」よって「問題形式」と「条件」を自動で入力する
    watch(() => gameConversation.value.timing, (newTiming) => {
      if (!state.conditions.length) return;

      const newConditions = state.conditions.filter(condition => condition.timings.includes(newTiming));
      if (newConditions.length === 1) {
        // MEMO: 選択されたタイミングで選べる条件が一つしかないときは、その条件を選択状態にしておく
        gameConversation.value.conversationConditionId = newConditions[0].id;
      }
      // 選択されたタイミングが「問題形式」選択不可の場合に「問題形式」を「未入力」にする
      if (state.formatIdDisabledTimings.some(timing => timing.value === newTiming)) {
        gameConversation.value.dkidsQuestionFormatId = null;
      }
      // 選択されたタイミングが「条件」選択不可の場合に「条件」を「指定なし」にする
      if (state.conversationConditionIdDisabledTimings.some(timing => timing.value === newTiming)) {
        gameConversation.value.conversationConditionId = 0;
      }
    });

    const loadAsyncData = async () => {
      const conditionRepo = new ConversationConditionsRepository();
      const formatRepo = new DkidsQuestionFormatsRepository();
      const { items: conditions } = await conditionRepo.index();
      state.conditions = conditions;
      const { items: formats } = await formatRepo.index();
      state.formats = formats;
    };

    loadAsyncData();

    return {
      state,
      isNew,
      formatIdDisabled,
      conversationConditionIdDisabled,
      conditionValueDisabled,
      filteredConditions,
      submit,
      gameConversation
    };
  }
});


import { defineComponent, ref } from "vue";
import { DailyQuestionFormatsRepository } from "@/repositories/Admins/DailyQuestionFormatsRepository";
import { DailyQuestionFormatModel } from "@/models/DailyQuestionFormatModel";
import DailyQuestionFormatTable from "@/components/Admins/DailyQuestionFormats/DailyQuestionFormatTable.vue";
import DailyQuestionFormatUpdateForm from "@/components/Admins/DailyQuestionFormats/DailyQuestionFormatUpdateForm.vue";

export default defineComponent({
  components: {
    DailyQuestionFormatTable,
    DailyQuestionFormatUpdateForm
  },
  setup() {
    const loading = ref<boolean>(true);
    const activeTab = ref<string>("0");
    const dailyQuestionFormats = ref<DailyQuestionFormatModel[]>([]);
    const questionFormats = ref<any>([]);
    const selectedQuestionFormats = ref<any>([]);
    const tabs = ref<any>([
      { value: "0", name: "年間日程" },
      { value: "04", name: "4月" },
      { value: "05", name: "5月" },
      { value: "06", name: "6月" },
      { value: "07", name: "7月" },
      { value: "08", name: "8月" },
      { value: "09", name: "9月" },
      { value: "10", name: "10月" },
      { value: "11", name: "11月" },
      { value: "12", name: "12月" },
      { value: "01", name: "1月" },
      { value: "02", name: "2月" },
      { value: "03", name: "3月" }
    ]);

    const fetchDailyQuestionFormats = async () => {
      const repository = new DailyQuestionFormatsRepository();
      const results = await repository.index();
      dailyQuestionFormats.value = results.items;
      questionFormats.value = results.questionFormats;
      loading.value = false;
    };
    fetchDailyQuestionFormats();

    const filteredDailyQuestionFormats = async (month) => {
      loading.value = true;
      activeTab.value = month;
      if (month === "0") await fetchDailyQuestionFormats();

      const result = dailyQuestionFormats.value.filter(item => {
        return item.playDate >= `${month}/01` && item.playDate <= `${month}/31`;
      });
      selectedQuestionFormats.value = result;
      loading.value = false;
    };

    return {
      loading,
      activeTab,
      dailyQuestionFormats,
      questionFormats,
      selectedQuestionFormats,
      tabs,
      filteredDailyQuestionFormats
    };
  }
});

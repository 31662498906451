import { BaseModel } from "@/models/BaseModel";
import dayjs from "dayjs"

export class DkidsQuestionModel extends BaseModel {
  // TODO: 型定義は後からまとめて行なっていきたい
  public status: string;
  public questionCid: any;
  public correctAnswer: any;
  public option1: any;
  public option2: any;
  public option3: any;
  public option4: any;
  public option5: any;
  public option6: any;
  public option7: any;
  public option8: any;
  public hint1: any;
  public hint2: any;
  public hint3: any;
  public questionImage: any;
  public explainImage: any;
  public explainLong: any;
  public explainShort: any;
  public grade: any;
  public format: any;
  public zuhanSize: any;
  public question: any;
  public id: any;
  public createdAt: any;
  public updatedAt: any;
  public wrongAnswer1: any;
  public wrongAnswer2: any;
  public wrongAnswer3: any;
  public option1Audio: any;
  public option2Audio: any;
  public questionAudio: any;

  pendingTableTrClass() {
    return this.status === "remanded" ? "is-rebated" : ""
  }

  gameParams() {
    const params = {
      id: this.questionCid,
      question: this.question,
      correct_answer: this.correctAnswer,
      option1: this.option1,
      option2: this.option2,
      option3: this.option3,
      option4: this.option4,
      option5: this.option5,
      option6: this.option6,
      option7: this.option7,
      option8: this.option8,
      hint1: this.hint1,
      hint2: this.hint2,
      hint3: this.hint3,
      question_image: this.questionImage,
      explain_image: this.explainImage,
      explain_long: this.explainLong,
      explain_short: this.explainShort,
      grade: this.grade,
      format: this.format,
      zuhan_size: this.zuhanSize,
      wrong_answer1: this.wrongAnswer1,
      wrong_answer2: this.wrongAnswer2,
      wrong_answer3: this.wrongAnswer3,
      option1_audio: this.option1Audio,
      option2_audio: this.option2Audio,
      question_audio: this.questionAudio
    }
    return params
  }

  detailPath() {
    return `/admins/dkids_question_pendings/${this.id}/edit`
  }

  showCreatedAt() {
    if (!this.createdAt) return ""

    return dayjs(this.createdAt).format("YYYY年MM月DD日\nHH時mm分ss秒")
  }

  showUpdatedAt() {
    if (!this.updatedAt) return ""

    return dayjs(this.updatedAt).format("YYYY年MM月DD日\nHH時mm分ss秒")
  }

  imageBaseUrl() {
    return "https://unko-dkids.s3-ap-northeast-1.amazonaws.com/zuhan/";
  }

  // option1をテキスト表示するかどうか
  option1TextDisplay() {
    return this.format === "読み上げスクエア2択";
  }
}


import { defineComponent, reactive } from "vue";
import NewsArticleForm from "@/components/Admins/NewsArticles/NewsArticleForm";
import { NewsArticleModel } from "@/models/NewsArticleModel";
import { NewsArticlesRepository } from "@/repositories/Admins/NewsArticlesRepository";

export default defineComponent({
  components: { NewsArticleForm },
  setup() {
    const state = reactive<
    {
      newsArticle: NewsArticleModel
    }
    >({
      newsArticle: new NewsArticleModel({})
    });
    const newsArticleRepository = new NewsArticlesRepository();
    const afterSubmit = () => {
      window.location.href = "/admins/news_articles";
    };

    const getRecord = async () => {
      const id = window.location.href.match(/\/([0-9].*)\/edit/)[1];
      const record = await newsArticleRepository.show(Number(id));
      state.newsArticle = record;
    };

    getRecord();

    return {
      state,
      afterSubmit
    };
  }
});

import { BaseModel } from "@/models/BaseModel";

export class ConversationConditionModel extends BaseModel {
  public id: number;
  public key: string;
  public name: string;

  isConditionValueNeeded() {
    return this.key === "correctly_answered_more_than"
  }
}

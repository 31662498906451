import {BaseRepository} from "@/repositories/BaseRepository";
import {DailyQuestionFormatModel} from "@/models/DailyQuestionFormatModel";

export class DailyQuestionFormatMultipleUpdatesRepository extends BaseRepository {
  async update(params = []) {
    const url = `/admins/daily_question_format_multiple_updates.json`;
    const multipleParams = params.map((item: any) => {
      const questionFormat = new DailyQuestionFormatModel(item);
      return questionFormat.toApiParams();
    });
    return await this._put(url, { daily_question_formats: multipleParams });
  }
}

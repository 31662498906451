import { BaseRepository } from "@/repositories/BaseRepository";
import { ConversationConditionModel } from "@/models/ConversationConditionModel";

export class ConversationConditionsRepository extends BaseRepository {
  async index(params = {}) {
    const url = `/admins/conversation_conditions.json`;
    const results = await this._get(url, params);
    const items = results.data.records.map((item: any) => {
      return new ConversationConditionModel(item);
    });
    const totalCount = results.data.total_count;
    return { items, totalCount }
  }
}


import { defineComponent, reactive } from "vue";
import UnkoCollectionForm from "@/components/Admins/UnkoCollections/UnkoCollectionForm";
import { UnkoCollectionModel } from "@/models/UnkoCollectionModel";
export default defineComponent({
  components: { UnkoCollectionForm },
  setup() {
    const state = reactive<
    {
      unkoCollection: UnkoCollectionModel
    }
    >({
      unkoCollection: new UnkoCollectionModel({})
    });
    const afterSubmit = () => {
      window.location.href = "/admins/unko_collections";
    };
    return {
      state,
      afterSubmit
    };
  }
});

import { BaseModel } from "@/models/BaseModel";

export class UserModel extends BaseModel {
  private role: string;

  isAdmin() {
    return this.role === "admin"
  }

  isPermitter() {
    return this.role === "permitter"
  }

  isPermittable() {
    return this.isPermitter() || this.isAdmin()
  }

  submitQuestionStatus() {
    return this.isPermitter() ? "published" : "bk_published"
  }

  pendingStatuses() {
    return this.isPermitter() ? this.permitterPendingQuestionStatuses() : this.adminPendingQuestionStatuses()
  }

  permitterPendingQuestionStatuses() {
    return [
      { key: "すべて", value: "" },
      { key: "校正待ち", value: "1" },
      { key: "承認待ち", value: "0" },
      { key: "差し戻し", value: "3" }
    ]
  }

  adminPendingQuestionStatuses() {
    return [
      { key: "すべて", value: "" },
      { key: "承認待ち", value: "0" },
      { key: "差し戻し", value: "3" }
    ]
  }
}

import { createApp } from "vue";
import router from "~/common/router";
import Index from "~/components/Admins/GameConversations/Index";
import New from "~/components/Admins/GameConversations/New";
import Edit from "~/components/Admins/GameConversations/Edit";
import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from "@oruga-ui/theme-bulma";

router("admins/game_conversations", ["index"], () => {
  document.addEventListener("DOMContentLoaded", () => {
    const index = createApp(Index);
    index.use(Oruga, bulmaConfig);
    index.mount("#index");
  });
});

router("admins/game_conversations", ["new"], () => {
  document.addEventListener("DOMContentLoaded", () => {
    const newPage = createApp(New);
    newPage.use(Oruga, bulmaConfig);
    newPage.mount("#new");
  });
});

router("admins/game_conversations", ["edit"], () => {
  document.addEventListener("DOMContentLoaded", () => {
    const editPage = createApp(Edit);
    editPage.use(Oruga, bulmaConfig);
    editPage.mount("#edit");
  });
});

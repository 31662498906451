import { axios } from "@/libs/axios";
import * as qs from "qs";

// getでネストしたクエリを投げるために必要↓
const paramsSerializer = (params: any) => qs.stringify(params);

export class BaseRepository {
  async _get(url: string, params = {}, _options = {}) {
    const results = await axios.get(url, { params, paramsSerializer });
    return results;
  }

  async _post(url: string, params = {}, _options = {}) {
    const results = await axios.post(url, params);
    return results;
  }

  async _put(url: string, params = {}, _options = {}) {
    const results = await axios.put(url, params);
    return results;
  }

  async _delete(url: string, params = {}, _options = {}) {
    const results = await axios.delete(url, { params, paramsSerializer });
    return results;
  }
}

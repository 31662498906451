
import { defineComponent, inject, PropType, ref } from "vue";
import { DailyQuestionFormatModel } from "@/models/DailyQuestionFormatModel";
import { DailyQuestionFormatMultipleUpdatesRepository } from "@/repositories/Admins/DailyQuestionFormatMultipleUpdatesRepository";

interface QuestionFormats {
  key: string,
  value: number
}

interface Oruga {
  loading: any,
  modal: any,
  notification: any,
  config: any
}

export default defineComponent({
  name: "DailyQuestionFormatUpdateForm",
  props: {
    dailyQuestionFormats: {
      type: Array as PropType<DailyQuestionFormatModel[]>,
      required: true
    },
    questionFormats: {
      type: Array as PropType<QuestionFormats[]>,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const updateLoading = ref<boolean>(false);

    const formatBulkUpdate = (id, event) => {
      // 一括変更で更新された値に各学年のフォーマットをセットする
      const target = props.dailyQuestionFormats.find(item => item.id === id);
      target.zeroGradeFormatId = event.target.value;
      target.firstGradeFormatId = event.target.value;
      target.secondGradeFormatId = event.target.value;
      target.thirdGradeFormatId = event.target.value;
      target.fourthGradeFormatId = event.target.value;
      target.fifthGradeFormatId = event.target.value;
      target.sixthGradeFormatId = event.target.value;
    };

    const oruga = inject<Oruga>("oruga");
    const multipleUpdate = async () => {
      try {
        updateLoading.value = true;
        const repository = new DailyQuestionFormatMultipleUpdatesRepository();
        await repository.update(props.dailyQuestionFormats);

        oruga.notification.open({
          message: "更新しました。",
          rootClass: "toast-notification",
          position: "top",
          variant: "success"
        });
      } catch (e) {
        oruga.notification.open({
          message: "更新に失敗しました。",
          rootClass: "toast-notification",
          position: "top",
          variant: "danger"
        });
      } finally {
        updateLoading.value = false;
      }
    };

    return {
      updateLoading,
      formatBulkUpdate,
      multipleUpdate
    };
  }
});
